import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ManigaUser } from 'api/chat/models/manigaUser.model';

export interface ManigaUserListState {
    isLoading: boolean,
    manigaUsers: ManigaUser[];
}

const initialState: ManigaUserListState = {
    isLoading: false,
    manigaUsers: [],
}

export const chatUserListSlice = createSlice({
    name: 'maniga-user-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setManigaUserList: (state, action: PayloadAction<ManigaUser[]>) => {
            const { payload } = action;
            state.manigaUsers = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setLoading, 
    setManigaUserList,
    dispose
} = chatUserListSlice.actions

export default chatUserListSlice.reducer