import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChatFilterModel } from 'api/admin/chat/models/chat-filter.model';
import { Chat } from 'api/admin/chat/models/chat.model'

export interface AdminChatListState {
    isLoading: boolean,
    chats: Chat[];
    filter: ChatFilterModel;
}

const initialState: AdminChatListState = {
    isLoading: false,
    chats: [],
    filter: {
        searchString: null,
        showPrivateChats: false,
        pagingSortingInfo: {
            page: 1,
            pageSize: 10,
            sortMember: "ChatMembersQty",
            sortDescending: true,
            totalItemCount: null
        }
    }
}

export const adminChatListSlice = createSlice({
    name: 'admin-chat-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setChatList: (state, action: PayloadAction<Chat[]>) => {
            const { payload } = action;
            state.chats = payload;
        },
        setFilter: (state, action: PayloadAction<ChatFilterModel>) => {
            const { payload } = action;
            state.filter = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setChatList, 
    setLoading,
    setFilter,
    dispose
} = adminChatListSlice.actions

export default adminChatListSlice.reducer