import styled from 'styled-components';

function Loading(){
    return <StyledSection>
        <span className="loader"></span>
    </StyledSection>
}

const StyledSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;

    .loader {
        width: 48px;
        height: 48px;
        border: 4px solid #4caf50;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
`

export default Loading