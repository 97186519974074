import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MatchDayDetails } from 'api/matchday/models/matchDayDetails.model';

export interface MatchdayDetailsState {
    isLoading: boolean,
    matchdayDetails: MatchDayDetails;
    isNotFound: boolean;
}

const initialState: MatchdayDetailsState = {
    isLoading: false,
    matchdayDetails: null,
    isNotFound: false
}

export const matchdayDetailsSlice = createSlice({
    name: 'matchday-details',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setIsNotFound: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isNotFound = payload;
        },
        setMatchDayDetails: (state, action: PayloadAction<MatchDayDetails>) => {
            const { payload } = action;
            state.matchdayDetails = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setMatchDayDetails, 
    setIsNotFound,
    setLoading,
    dispose
} = matchdayDetailsSlice.actions

export default matchdayDetailsSlice.reducer