import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChatInfo } from 'api/admin/chat/models/chat-info.model';
import { MatchdayFilterModel } from 'api/admin/matchday/models/matchday-filter.model';
import { Matchday } from 'api/admin/matchday/models/matchday.model';

export interface AdminMatchDayListState {
    isLoading: boolean,
    chatId: number,
    matchdays: Matchday[];
    filter: MatchdayFilterModel;
    isNotFound: boolean;
    chat: ChatInfo;
}

const initialState: AdminMatchDayListState = {
    isLoading: false,
    chatId: null,
    matchdays: [],
    isNotFound: false,
    chat: null,
    filter: {
        searchString: null,
        pagingSortingInfo: {
            page: 1,
            pageSize: 10,
            sortMember: "CreatedDate",
            sortDescending: true,
            totalItemCount: null
        }
    }
}

export const adminChatListSlice = createSlice({
    name: 'admin-matchday-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setCurrentChat: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            state.chatId = payload;
        },
        setMatchDayList: (state, action: PayloadAction<Matchday[]>) => {
            const { payload } = action;
            state.matchdays = payload;
        },
        setFilter: (state, action: PayloadAction<MatchdayFilterModel>) => {
            const { payload } = action;
            state.filter = payload;
        },
        setIsNotFound: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isNotFound = payload;
        },
        setChatInfo: (state, action: PayloadAction<ChatInfo>) => {
            const { payload } = action;
            state.chat = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setCurrentChat,
    setMatchDayList, 
    setLoading,
    setFilter,
    setIsNotFound,
    setChatInfo,
    dispose
} = adminChatListSlice.actions

export default adminChatListSlice.reducer