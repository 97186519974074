import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PriorityUser } from 'api/chat/models/priorityUser.model';

export interface ChatPriorityPlayersState {
    isLoading: boolean
    users: PriorityUser[]
    isModalOpen: boolean
    isSuggestionsLoading: boolean
    suggestions: PriorityUser[]
    votePriority: boolean
    isSaveChangesProcessing: boolean
}

const initialState: ChatPriorityPlayersState = {
    isLoading: false,
    users: [],
    isModalOpen: false,
    isSuggestionsLoading: false,
    suggestions: [],
    votePriority: false,
    isSaveChangesProcessing: false
}

export const chatPriorityPlayersSlice = createSlice({
    name: 'chat-priority-players',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setPriorityPlayers: (state, action: PayloadAction<PriorityUser[]>) => {
            const { payload } = action;
            state.users = payload;
        },
        setSuggestions: (state, action: PayloadAction<PriorityUser[]>) => {
            const { payload } = action;
            state.suggestions = payload;
        },
        setSuggestionsLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isSuggestionsLoading = payload;
        },
        setVotePriority: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.votePriority = payload;
        },
        toggleModal: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isModalOpen = payload;
        },
        setSaveChangesProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isSaveChangesProcessing = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setPriorityPlayers,
    setSuggestions,
    toggleModal, 
    setLoading,
    setSuggestionsLoading,
    setVotePriority,
    setSaveChangesProcessing,
    dispose
} = chatPriorityPlayersSlice.actions

export default chatPriorityPlayersSlice.reducer