import axios from "axios";
import config from "config";
import { anonymousPaths } from "routes/paths";
// const https = require('https')

const token = localStorage.getItem('jwt');

const handleServerCrashError = (error: any) => {
  if (axios.isCancel(error)) {
      return false;
  } else if (error.response?.status === 401) {
      localStorage.removeItem('jwt');
      window.location.replace(anonymousPaths.landing);
  } else if (error.response?.status === 404) {
    return error.response;
  } else {
      console.error(error.toString());
      return Promise.reject(error);
  }
};

const axiosInstance = axios.create({
  baseURL: config.baseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: process.env.NODE_ENV === 'production' ? true : false,
  // }),
  timeout: 30000,
});

function apiSetHeader (name: any, value: string) {
  if (value) {
    axiosInstance.defaults.headers.common[name] = value;
  }
};

if (token) {
  apiSetHeader('Authorization', `Bearer ${token}`);
}

let registerInterceptors = () => {
  axiosInstance.interceptors.response.use(
      response => response,
      handleServerCrashError,
  );

  axiosInstance.interceptors.request.use(config => {
    return config;
  });
};

registerInterceptors();

const CancelToken = axios.CancelToken;

export const getCancelTokenSource = () => CancelToken.source();

export default axiosInstance;
