import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Chat } from 'api/chat/models/chat.model'

export interface ChatSettingsState {
    isLoading: boolean
    chat: Chat
    isNotFound: boolean
}

const initialState: ChatSettingsState = {
    isLoading: false,
    chat: null,
    isNotFound: false
}

export const chatSettingsSlice = createSlice({
    name: 'chat-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setChatSettings: (state, action: PayloadAction<Chat>) => {
            const { payload } = action;
            state.chat = payload;
        },
        setIsNotFound: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isNotFound = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setChatSettings, 
    setIsNotFound,
    setLoading,
    dispose
} = chatSettingsSlice.actions

export default chatSettingsSlice.reducer