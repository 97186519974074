import { lazy } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store';
const AnonymRoutes = lazy(() => import('./routes/anonym-routes'));
const PlayerRoutes = lazy(() => import('./routes/player-routes'));
const AdminRoutes = lazy(() => import('./routes/admin-routes'));
import { Actions as AuthActions, Selectors as AuthSelectors } from 'components/login/auth.controller';
import { useEffect } from 'react';
import { Loading } from 'components/shared/loading';
import { ToastContainer } from 'react-toastify';
import 'custom.scss'
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/uk'
import 'moment/locale/ru'

type StateProps = {
    isLoggedIn: boolean
    isParseTokenProcessing: boolean
    isLoginProcessing: boolean
    isGlobalAdmin: boolean
}

type DispatchProps = {
    parseToken: () => void
}

function App(props: StateProps & DispatchProps){
    useEffect(() => {
        props.parseToken();
    }, [])

    const renderRoutes = () => {
        const { isLoggedIn, isGlobalAdmin } = props;
        if(!isLoggedIn)
            return <AnonymRoutes />;
            
        if(isGlobalAdmin)
            return <AdminRoutes />;
        
        return <PlayerRoutes />;
    }

    const { 
        isParseTokenProcessing, 
        isLoginProcessing 
    } = props;

    if(isParseTokenProcessing || isLoginProcessing){
        return <Loading />
    }

    return <>
        {renderRoutes()}
        <ToastContainer />
    </>
}

const mapStateToProps = (state: AppState) => {
    const { 
        token, 
        isParseTokenProcessing, 
        isLoginProcessing,
        isGlobalAdmin
    } = AuthSelectors.getAuth(state);
    return {
        isLoggedIn: !!token,
        isParseTokenProcessing: isParseTokenProcessing,
        isLoginProcessing: isLoginProcessing,
        isGlobalAdmin: isGlobalAdmin
    }
}

const mapDispatchToProps = {
    parseToken: AuthActions.parseToken
}

export default connect(mapStateToProps, mapDispatchToProps)(App)