import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TeamColor } from 'api/team/models/set-team-color-item.model'

export type TeamColorItem = {
    teamId: number
    teamName: string
    color?: TeamColor
}

export interface SetTeamColorsModalState {
    isModalOpen: boolean
    isProcessing: boolean
    items: TeamColorItem[]
}

const initialState: SetTeamColorsModalState = {
    isModalOpen: false,
    isProcessing: false,
    items: []
}

export const setTeamColorsModalSlice = createSlice({
    name: 'set-team-colors-modal',
    initialState,
    reducers: {
        toggleModal: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isModalOpen = payload;
        },
        setIsProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isProcessing = payload;
        },
        setItems: (state, action: PayloadAction<TeamColorItem[]>) => {
            const { payload } = action;
            state.items = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    toggleModal,
    setIsProcessing,
    setItems,
    dispose
} = setTeamColorsModalSlice.actions

export default setTeamColorsModalSlice.reducer