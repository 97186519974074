import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ManigaUser } from 'api/chat/models/manigaUser.model';
import { Top3UserRatingsResponse } from 'api/chat/models/top3UserRatingsResponse.model';

export interface ChatTopManigaPlayersState {
    isLoading: boolean,
    users: ManigaUser[];
}

const initialState: ChatTopManigaPlayersState = {
    isLoading: false,
    users: [],
}

export const chatTopManigaPlayersSlice = createSlice({
    name: 'chat-top-maniga-players',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setTopManigaPlayers: (state, action: PayloadAction<ManigaUser[]>) => {
            const { payload } = action;
            state.users = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setTopManigaPlayers, 
    setLoading,
    dispose
} = chatTopManigaPlayersSlice.actions

export default chatTopManigaPlayersSlice.reducer