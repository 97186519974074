import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MatchdayInfo } from 'api/admin/matchday/models/matchday-info.model';
import { VoteFilterModel } from 'api/admin/matchday/models/vote-filter.model';
import { Vote } from 'api/admin/matchday/models/vote.model';

export interface AdminVoteListState {
    isLoading: boolean,
    matchdayId: number,
    votes: Vote[];
    filter: VoteFilterModel;
    isNotFound: boolean;
    matchday: MatchdayInfo;
}

const initialState: AdminVoteListState = {
    isLoading: false,
    matchdayId: null,
    votes: [],
    isNotFound: false,
    matchday: null,
    filter: {
        searchString: null,
        pagingSortingInfo: {
            page: 1,
            pageSize: 10,
            sortMember: "Type",
            sortDescending: false,
            totalItemCount: null
        }
    }
}

export const adminChatListSlice = createSlice({
    name: 'admin-matchday-vote-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setCurrentMatchday: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            state.matchdayId = payload;
        },
        setVoteList: (state, action: PayloadAction<Vote[]>) => {
            const { payload } = action;
            state.votes = payload;
        },
        setFilter: (state, action: PayloadAction<VoteFilterModel>) => {
            const { payload } = action;
            state.filter = payload;
        },
        setIsNotFound: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isNotFound = payload;
        },
        setMatchdayInfo: (state, action: PayloadAction<MatchdayInfo>) => {
            const { payload } = action;
            state.matchday = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setCurrentMatchday,
    setVoteList, 
    setLoading,
    setFilter,
    setIsNotFound,
    setMatchdayInfo,
    dispose
} = adminChatListSlice.actions

export default adminChatListSlice.reducer