import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChatUserStat } from 'api/chat/models/chatUserStat.model';

export interface ChatStatListState {
    isLoading: boolean
    stats: ChatUserStat[]
}

const initialState: ChatStatListState = {
    isLoading: false,
    stats: [],
}

export const chatStatListSlice = createSlice({
    name: 'chat-stat-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setChatStatList: (state, action: PayloadAction<ChatUserStat[]>) => {
            const { payload } = action;
            state.stats = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setLoading, 
    setChatStatList,
    dispose
} = chatStatListSlice.actions

export default chatStatListSlice.reducer