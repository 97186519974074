import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Chat } from 'api/chat/models/chat.model'
import { User } from 'api/chat/models/user.model';
import { ChatUserRating } from 'api/chat/models/chatUserRating.model';
import { UserList } from 'api/chat/models/userList.model';
import { SetUserRatingModel } from 'api/chat/models/set-user-rating.model';

export interface ChatUserListState {
    isLoading: boolean
    chat: Chat
    users: Array<User>
    chatUserRating: ChatUserRating
    pendingRatings: SetUserRatingModel[]
    saveChangesProcessing: boolean
}

const initialState: ChatUserListState = {
    isLoading: false,
    chat: null,
    chatUserRating: null,
    users: [],
    pendingRatings: [],
    saveChangesProcessing: false
}

export const chatUserListSlice = createSlice({
    name: 'chat-user-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setChatUserList: (state, action: PayloadAction<UserList>) => {
            const { payload } = action;
            state.users = payload.users;
            state.chat = payload.chat;
            state.chatUserRating = payload.chatUserRating;
        },
        setUserRating: (state, action: PayloadAction<SetUserRatingModel>) => {
            const { payload } = action;

            const userId = payload.userId;
            const newRating = payload.rating;
            const users = state.users;
            const pendingRatings = state.pendingRatings;
            const existingUserRating = pendingRatings.find(x => x.userId === userId);
            const user = users.find(x => x.id === userId);
            user.rating = newRating;

            if(existingUserRating){
                existingUserRating.rating = newRating;
            } else {
                pendingRatings.push(payload);
            }

            state.pendingRatings = [...pendingRatings];
            state.users = [...users];
        },
        setSaveChangesProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.saveChangesProcessing = payload;
        },
        clearPendingRatings: (state) => {
            state.pendingRatings = [];
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setLoading, 
    setChatUserList,
    setSaveChangesProcessing,
    setUserRating,
    clearPendingRatings,
    dispose
} = chatUserListSlice.actions

export default chatUserListSlice.reducer