import { combineReducers } from 'redux';
import chatListReducer from './chat-list.slice'
import chatUserListReducer from './chat-user-list.slice'
import chatDetailsReducer from './chat-details.slice'
import chatSettingsReducer from './chat-settings.slice'
import topPlayersReducer from './chat-top-players.slice'
import priorityPlayersReducer from './chat-priority-players.slice'
import playerStatsReducer from './chat-player-stats.slice'
import statListReducer from './chat-stat-list.slice'
import topManigaPlayersReducer from './chat-top-maniga-players.slice'

const chatRootReducer = combineReducers({
    list: chatListReducer,
    userList: chatUserListReducer,
    details: chatDetailsReducer,
    settings: chatSettingsReducer,
    topPlayers: topPlayersReducer,
    priorityPlayers: priorityPlayersReducer,
    playerStats: playerStatsReducer,
    statList: statListReducer,
    topManigaPlayers: topManigaPlayersReducer,
});

export default chatRootReducer;