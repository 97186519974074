import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChatDetails } from 'api/chat/models/chatDetails.model';

export interface ChatDetailsState {
    isLoading: boolean
    chatDetails: ChatDetails
    isNotFound: boolean
    isRefreshChatMembersProcessing: boolean
}

const initialState: ChatDetailsState = {
    isLoading: false,
    chatDetails: null,
    isNotFound: false,
    isRefreshChatMembersProcessing: false
}

export const chatDetailsSlice = createSlice({
    name: 'chat-details',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setIsNotFound: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isNotFound = payload;
        },
        setChatDetails: (state, action: PayloadAction<ChatDetails>) => {
            const { payload } = action;
            state.chatDetails = payload;
        },
        setRefreshChatMembersProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isRefreshChatMembersProcessing = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setChatDetails, 
    setIsNotFound,
    setLoading,
    setRefreshChatMembersProcessing,
    dispose
} = chatDetailsSlice.actions

export default chatDetailsSlice.reducer