import { combineReducers } from 'redux';
import chatRootReducer from './chat'
import matchdayRootReducer from './matchday'
import adminUserRootReducer from './user';

const adminRootReducer = combineReducers({
    chat: chatRootReducer,
    matchday: matchdayRootReducer,
    user: adminUserRootReducer,
});

export default adminRootReducer;