import { createSlice, original } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { EditMatchGoalsModel } from 'api/match/models/edit-match-goals.model'
import { Goal } from 'api/match/models/goal.model'

export interface EditMatchModalState {
    matchId: number
    isModalOpen: boolean
    isProcessing: boolean
    isLoading: boolean
    info: EditMatchGoalsModel
}

const initialState: EditMatchModalState = {
    matchId: null,
    isModalOpen: false,
    isProcessing: false,
    isLoading: false,
    info: null
}

export const customTeamModalSlice = createSlice({
    name: 'edit-match-modal',
    initialState,
    reducers: {
        toggleModal: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isModalOpen = payload;
        },
        setMatchId: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            state.matchId = payload;
        },
        setIsProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isProcessing = payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setInfo: (state, action: PayloadAction<EditMatchGoalsModel>) => {
            const { payload } = action;
            state.info = payload;
        },
        setScorer: (state, action: PayloadAction<{ goal: Goal, voteId: number }>) => {
            const { payload } = action;
            const { homeGoals, visitorGoals} = state.info;
            const goal = [...homeGoals, ...visitorGoals].find(x => original(x) === payload.goal);
            goal.scorerVoteId = payload.voteId;
        },
        setAssist: (state, action: PayloadAction<{ goal: Goal, voteId: number }>) => {
            const { payload } = action;
            const { homeGoals, visitorGoals} = state.info;
            const goal = [...homeGoals, ...visitorGoals].find(x => original(x) === payload.goal);
            goal.assistVoteId = payload.voteId;
        },
        setOwnGoal: (state, action: PayloadAction<{ goal: Goal, value: boolean }>) => {
            const { payload } = action;
            const { homeGoals, visitorGoals} = state.info;
            const goal = [...homeGoals, ...visitorGoals].find(x => original(x) === payload.goal);
            goal.isOwnGoal = payload.value;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    toggleModal,
    setMatchId,
    setIsProcessing,
    setLoading,
    setInfo,
    setScorer,
    setAssist,
    setOwnGoal,
    dispose
} = customTeamModalSlice.actions

export default customTeamModalSlice.reducer