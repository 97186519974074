import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LoginResponseModel } from 'api/auth/models/login-response.model'

export interface AuthState extends LoginResponseModel {
    isLoading: boolean
    isParseTokenProcessing: boolean
    isLoginProcessing: boolean
}

const initialState: AuthState = {
    token: null,
    userId: null,
    firstName: null,
    lastName: null,
    username: null,
    isGlobalAdmin: false,
    adminChatIds: [],
    isLoading: false,
    isParseTokenProcessing: true,
    isLoginProcessing: false
}

export const authSlice = createSlice({
    name: 'auth',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setAuthData: (state, action: PayloadAction<LoginResponseModel>) => {
            const { payload } = action;
            state.userId = payload.userId;
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
            state.username = payload.username;
            state.isGlobalAdmin = payload.isGlobalAdmin;
            state.adminChatIds = payload.adminChatIds;
        },
        clearAuthData: (state) => {
            state = initialState;
        },
        setToken: (state, action: PayloadAction<string>) => {
            const { payload } = action;
            state.token = payload;
        },
        setParseTokenProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isParseTokenProcessing = payload;
        },
        setLoginProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoginProcessing = payload;
        },
    },
})

export const { 
    setAuthData, 
    clearAuthData, 
    setLoading, 
    setToken, 
    setParseTokenProcessing, 
    setLoginProcessing 
} = authSlice.actions

export default authSlice.reducer