import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Chat } from 'api/chat/models/chat.model'
import { MatchDayList } from 'api/matchday/models/matchDayList.model';
import { MatchDay } from 'api/matchday/models/matchDay.model';

export interface MatchDayListState {
    isLoading: boolean,
    chat: Chat;
    matchDays: Array<MatchDay>;
}

const initialState: MatchDayListState = {
    isLoading: false,
    chat: null,
    matchDays: [],

}

export const matchDayListSlice = createSlice({
    name: 'matchday-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setMatchDayList: (state, action: PayloadAction<MatchDayList>) => {
            const { payload } = action;
            state.chat = payload.chat;
            state.matchDays = payload.matchDays;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setLoading, 
    setMatchDayList,
    dispose
} = matchDayListSlice.actions

export default matchDayListSlice.reducer