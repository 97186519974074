import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Chat } from 'api/chat/models/chat.model'
import { SaveUserProfileModel } from 'api/user-profile/models/save-user-profile.model'

export interface UserProfileState {
    isLoading: boolean
    userProfile: SaveUserProfileModel
}

const initialState: UserProfileState = {
    isLoading: false,
    userProfile: null
}

export const userProfileSlice = createSlice({
    name: 'user-profile',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        saveUserProfile: (state, action: PayloadAction<SaveUserProfileModel>) => {
            const { payload } = action;
            state.userProfile = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    saveUserProfile, 
    setLoading,
    dispose
} = userProfileSlice.actions

export default userProfileSlice.reducer