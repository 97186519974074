import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MatchList } from 'api/match/models/matchList.model';
import { Match } from 'api/match/models/match.model';
import { TeamResult } from 'api/match/models/teamResult.model';

export interface MatchListState {
    isLoading: boolean,
    teamResults: TeamResult[];
    matches: Match[];
}

const initialState: MatchListState = {
    isLoading: false,
    teamResults: null,
    matches: [],
}

export const matchListSlice = createSlice({
    name: 'match-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setMatchList: (state, action: PayloadAction<MatchList>) => {
            const { payload } = action;
            state.teamResults = payload.teamResults;
            state.matches = payload.matches;
        },
        updateMatchItem: (state, action: PayloadAction<Match>) => {
            const { payload } = action;
            const match = state.matches.find(x => x.id == payload.id);
            match.teams = payload.teams;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setLoading, 
    setMatchList,
    updateMatchItem,
    dispose
} = matchListSlice.actions

export default matchListSlice.reducer