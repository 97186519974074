import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChatUserRating } from 'api/chat/models/chatUserRating.model';
import { Top3UserRatingsResponse } from 'api/chat/models/top3UserRatingsResponse.model';
import { User } from 'api/chat/models/user.model';

export interface ChatTopPlayersState {
    isLoading: boolean,
    ratings: ChatUserRating[];
    users: User[];
}

const initialState: ChatTopPlayersState = {
    isLoading: false,
    ratings: [],
    users: []
}

export const chatTopPlayersSlice = createSlice({
    name: 'chat-top-players',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setTopPlayers: (state, action: PayloadAction<Top3UserRatingsResponse>) => {
            const { payload } = action;
            state.ratings = payload.ratings;
            state.users = payload.users;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setTopPlayers, 
    setLoading,
    dispose
} = chatTopPlayersSlice.actions

export default chatTopPlayersSlice.reducer