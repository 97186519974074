import { CancelToken } from 'axios';
import axios from 'axios-instance'

import { LoginResponseModel } from './models/login-response.model';
import { LoginArgs } from './models/login.model';

export default class AuthService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async loginByTelegram(model: any) {
        const { data } = await axios.post<LoginResponseModel>(
            `api/Account/TelegramLogin`,
            model,
            this.axiosConfig
        );

        return data;
    }

    public static async login(args: LoginArgs) {
        const { data } = await axios.post<LoginResponseModel>(
            `api/Account/Authenticate`,
            args,
            this.axiosConfig
        );

        return data;
    }

    public static async refreshAuth() {
        const { data } = await axios.post<LoginResponseModel>(
            `api/Account/RefreshAuth`,
            {},
            this.axiosConfig
        );

        return data;
    }    
}