import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AdminUserModel } from "api/admin/user/models/admin-user.model";
import { AdminUserFilterModel } from "api/admin/user/models/admin-user-filter.model";


export interface AdminUserListState {
    isLoading: boolean;
    users: AdminUserModel[];
    filter: AdminUserFilterModel;
}

const initialState: AdminUserListState = {
    isLoading: false,
    users: [],
    filter: {
        searchString: null,
        pagingSortingInfo: {
            page: 1,
            pageSize: 10,
            sortMember: "chatsQty",
            sortDescending: true,
            totalItemCount: null,
        }
    }
}

export const adminUserListSlice = createSlice({
    name: "admin-user-list",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action
            state.isLoading = payload
        },
        setUsersList: (state, action: PayloadAction<AdminUserModel[]>) => {
            const { payload } = action
            state.users = payload
        },
        setFilter: (state, action: PayloadAction<AdminUserFilterModel>) => {
            const { payload } = action
            state.filter = payload
        },
        dispose: () => {
            return initialState;
        }
    },
})

export const {
    setLoading,
    setUsersList,
    setFilter,
    dispose
} = adminUserListSlice.actions

export default adminUserListSlice.reducer