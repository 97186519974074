import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Chat } from 'api/chat/models/chat.model'
import { MatchDayList } from 'api/matchday/models/matchDayList.model';
import { MatchDay } from 'api/matchday/models/matchDay.model';
import { RecentMatchDaysResponseModel } from 'api/matchday/models/recent-matchdays-response.model';

export interface RecentMatchDaysState {
    isLoading: boolean,
    matchDays: Array<MatchDay>;
}

const initialState: RecentMatchDaysState = {
    isLoading: false,
    matchDays: [],
}

export const recentMatchDaysSlice = createSlice({
    name: 'recent-matchdays',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setMatchDayList: (state, action: PayloadAction<RecentMatchDaysResponseModel>) => {
            const { payload } = action;
            state.matchDays = payload.matchDays;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setLoading, 
    setMatchDayList,
    dispose
} = recentMatchDaysSlice.actions

export default recentMatchDaysSlice.reducer