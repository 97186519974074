import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { BotConfiguration } from 'api/configuration/models/bot-configuration.model';

export interface ConfigurationState {
    isLoading: boolean
    configuration: BotConfiguration
}

const initialState: ConfigurationState = {
    isLoading: false,
    configuration: null
}

export const configurationSlice = createSlice({
    name: 'bot-configuration',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setConfiguration: (state, action: PayloadAction<BotConfiguration>) => {
            const { payload } = action;
            state.configuration = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setConfiguration, 
    setLoading,
    dispose
} = configurationSlice.actions

export default configurationSlice.reducer