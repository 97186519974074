import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PlayerStatsResponseModel } from 'api/matchday/models/player-stats-response.model';
import { PlayerStatsItem } from 'api/matchday/models/player-stats.model';

export interface MatchDayPlayerStatsState {
    isLoading: boolean,
    stats: PlayerStatsItem[];
}

const initialState: MatchDayPlayerStatsState = {
    isLoading: false,
    stats: null,
}

export const playerStatsSlice = createSlice({
    name: 'matchday-player-stats',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setPlayerStats: (state, action: PayloadAction<PlayerStatsResponseModel>) => {
            const { payload } = action;
            state.stats = payload.stats;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setLoading, 
    setPlayerStats,
    dispose
} = playerStatsSlice.actions

export default playerStatsSlice.reducer