import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export type CustomTeamPlayerItem = {
    voteId: number
}

export interface CustomTeamModalState {
    teamId: number
    isModalOpen: boolean
    isProcessing: boolean
    items: CustomTeamPlayerItem[]
}

const initialState: CustomTeamModalState = {
    teamId: null,
    isModalOpen: false,
    isProcessing: false,
    items: []
}

export const customTeamModalSlice = createSlice({
    name: 'custom-team-modal',
    initialState,
    reducers: {
        toggleModal: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isModalOpen = payload;
        },
        setTeamId: (state, action: PayloadAction<number>) => {
            const { payload } = action;
            state.teamId = payload;
        },
        setItems: (state, action: PayloadAction<CustomTeamPlayerItem[]>) => {
            const { payload } = action;
            state.items = payload;
        },
        setIsProcessing: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isProcessing = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    toggleModal,
    setTeamId,
    setIsProcessing,
    setItems,
    dispose
} = customTeamModalSlice.actions

export default customTeamModalSlice.reducer