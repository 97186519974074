import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChatUserStat } from 'api/chat/models/chatUserStat.model';
import { Top3UserRatingsResponse } from 'api/chat/models/top3UserRatingsResponse.model';
import { User } from 'api/chat/models/user.model';

export interface ChatPlayerStatsState {
    isLoading: boolean,
    statsInfo: ChatUserStat;
}

const initialState: ChatPlayerStatsState = {
    isLoading: false,
    statsInfo: null,
}

export const chatPlayerStatsSlice = createSlice({
    name: 'chat-player-stats',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setPlayerStats: (state, action: PayloadAction<ChatUserStat>) => {
            const { payload } = action;
            state.statsInfo = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setPlayerStats, 
    setLoading,
    dispose
} = chatPlayerStatsSlice.actions

export default chatPlayerStatsSlice.reducer