import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'store'
import { Chat } from 'api/chat/models/chat.model'
import { ManigaUser } from 'api/chat/models/manigaUser.model';

export interface ChatListState {
    isLoading: boolean,
    chats: Chat[];
}

const initialState: ChatListState = {
    isLoading: false,
    chats: []
}

export const chatListSlice = createSlice({
    name: 'chat-list',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action;
            state.isLoading = payload;
        },
        setChatList: (state, action: PayloadAction<Chat[]>) => {
            const { payload } = action;
            state.chats = payload;
        },
        dispose: () => {
            return initialState;
        },
    },
})

export const { 
    setChatList, 
    setLoading,
    dispose
} = chatListSlice.actions

export default chatListSlice.reducer